<template>
    <div>
        <b-modal id="user-current-plan-modal" hide-footer size="md" centered title="Add User Payment" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{ errorMsg }}</b-alert>
                <p style="margin-bottom: 2px;">Plans*</p>
                <v-select 
                        class="w-100 mb-1"
                        v-model="record.plan_id"
                        placeholder="Select Plan"
                        :clearable="false"
                        :reduce="(option) => option._id"
                        :options="filteredPlans"
                        @input="onChangePlan"
                        label="name"
                    >
                    <template v-slot:option="option">
                        {{ option.name }} - {{ option.price }}
                    </template>
                </v-select>

                <p style="margin-bottom: 2px;">Payment Type*</p>
                <v-select 
                        class="w-100 mb-1"
                        v-model="record.type"
                        placeholder="payment type"
                        :clearable="false"
                        :reduce="(option) => option"
                        :options="payment_methods"
                        label="name"
                    >
                    <template v-slot:option="option">
                        {{ option.name }}
                    </template>
                </v-select>
                        <p class="mb-0 ">Price</p>
                        <div class="input-setting mb-1">
                            <b-input-group size="lg" :prepend="record.currency_symbol" :append="record.currency_code">
                                <b-form-input v-model="record.price" placeholder="Price" type="text" />
                            </b-input-group>
                </div>
     
                <p style="margin-bottom: 2px;">Date Paid*</p>          
              <flat-pickr
                     v-model="record.date"
                    :config="configDate"
                    class="form-control"
                    placeholder="Select date"
                    name="date"/>

                    <b-form-checkbox
                    id="checkbox-1"
                    class="mt-2"
                    v-model="record.customExpired"
                    name="checkbox-1"
                    value="accepted"
                    @input="onChangeCustomExpired"
                  >
                    Custom Expiry
                  </b-form-checkbox>    
                 <div v-if="record.customExpired" class="row mt-1">
                  <div class="col-md-6">
                    <flat-pickr
                    v-model="record.startDate"
                    :config="configDate"
                    class="form-control"
                    placeholder="Start date"
                    name="date"/>
                  </div>

                   <div class="col-md-6">
                    <flat-pickr
                   v-model="record.endDate"
                   :config="configDate"
                   class="form-control"
                   placeholder="End date"
                   name="date"/>

                   </div>
                   <p v-if="record && record.startDate && record.endDate && record.startDate >= record.endDate" style="margin-top: 2px;" class="text-danger col-md-8">start date must be less than end date</p>
                 </div>


                <p style="margin-bottom: 2px;margin-top: 10px;">Note *</p>
                <b-form-textarea
                    class="mb-1"
                    v-model="record.note"
                    placeholder="Note"
                    rows="5"
                ></b-form-textarea>

        

                <div class="modal-footer px-0">
                    <b-button @click="saveBtn" :disabled="isFormValid()" variant="primary">
                        <b-spinner v-if="loading" small />
                        Update
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BAlert,
    BSpinner,
    BInputGroup,
    BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../Helpers';
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from "moment";
export default {
    components: {
        BButton,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
        vSelect,
        flatPickr,
        BInputGroup,
        BFormCheckbox,
    },
    data() {
        return {
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {
                billing_cycle: '',
                plan_id: '',
                note: '',
            },
        payment_methods: [
        'Cash',
        'Easypaisa',
        'Jazzcash',
        'Check',
        'Offline credit card',
        'Cash App',
        'Money order',
        'Other'
      ],
      selectedMethod:null,
      configDate:{ 
                dateFormat: 'm/d/Y',
                enableTime: true,
                time_24hr: false,  
                minDate : moment().format('MM/DD/YYYY')    
                }
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
        filteredPlans() {
            if (!this.record.billing_cycle) {
                return this.get_plans;
            }
            return this.get_plans.filter(plan => plan.billing_cycle === this.record.billing_cycle);
        },
      
    },
    watch: {
        selected_user: function (newVal) {
            if(!this.selected_user.current_plan){
                    const freePlan = _.find(this.filteredPlans, { price: '0' });
                    if (freePlan) {   
                        this.record.current_plan = freePlan;
                        this.record.plan_id = freePlan._id;
                    }
            } else {
                this.record.plan_id = this.selected_user.currentPlanId;
                this.record.type = this.selected_user.paymentType;
                this.record.note = this.selected_user.notes;
                if (this.selected_user.current_plan) {
                 this.record.price = this.selected_user.current_plan.price;
                }
            const selectedCurrency = this.get_currencies.find(c=>c._id == this.selected_user.current_plan.currency_id)
            if (selectedCurrency) {
                this.record.currency_id = selectedCurrency._id
                this.record.currency_symbol = selectedCurrency.symbol
                this.record.currency_code = selectedCurrency.code
            }
            this.record.date = this.selected_user.date ? this.selected_user.date : moment().format('MM/DD/YYYY');
         console.log(this.selected_user)


                
            }
        }, 

    },
    methods: {
    isFormValid() {
        const commonMissing = this.loading || !this.record.plan_id || !this.record.type || !this.record.note || !this.record.date;
        
        if (!this.record.customExpired) {
            return commonMissing;
        } else {
            const datesMissing = !this.record.startDate || !this.record.endDate;
            const invalidDates = this.record.startDate && this.record.endDate &&
            new Date(this.record.startDate) >= new Date(this.record.endDate);
            return commonMissing || datesMissing || invalidDates;
        }
        },
        onChangeCustomExpired(){
          if (!this.record.customExpired) {
            this.record.startDate = null
            this.record.endDate = null
          }
        },
        onChangePlan(plan){
         const selectedPlan = this.filteredPlans.find(p=>p._id == plan);  
        if (selectedPlan) {
            this.record.price = selectedPlan.price;
            const selectedCurrency = this.get_currencies.find(c=>c._id == selectedPlan.currency_id)
            if (selectedCurrency) {
                this.record.currency_id = selectedCurrency._id
                this.record.currency_symbol = selectedCurrency.symbol
                this.record.currency_code = selectedCurrency.code
            }

          }
        },
     
        changeBillingCycleDropdown(){
            this.record.plan_id = '';
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
        console.log("--------------",)
          this.$emit('close');

            this.errorMsg = "";
            this.record = {
                billing_cycle: '',
                plan_id: null,
                note: '',
                currency_id:'',
                currency_symbol:'',
                currency_code:'',
                price:'',
                startDate:'',
                endDate:'',
                customExpired:false
            };
        },
        async saveBtn() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            try {
                const payload = {
                    register_user_id: this.selected_user._id,
                    plan_id: this.record.plan_id,
                    note: this.record.note,
                    type:this.record.type,
                    date:this.record.date,
                    customExpired:this.customExpired  || false,
                    startDate:this.record.startDate,
                    endDate:this.record.endDate,
                    price:this.record.price,
                    currency_id:this.record.currency_id,
                    currency_symbol:this.record.currency_symbol,
                    country_code:this.record.country_code,
                    platform:this.selected_user.platform

                }
                const resp = await axios.post(process.env.VUE_APP_API_URL + "/user/offline/plan/update",
                    payload,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitPlanUpdated');
                this.$bvModal.hide("user-current-plan-modal");
                this.loading = false;
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.note) {
                this.errorMsg = "Note field id required."
                return;
            }
            return "ok";
        },
        selectCurrency(item){
            this.record.currency_id = item._id
            this.record.currency_symbol = item.symbol
            this.record.currency_code = item.code
        },
    },
    props: [
        "manage",
        "get_plans",
        "get_billing_cycles",
        "selected_user",
        "get_currencies"
    ],
};
</script>
