<template>
    <div>
        <span style="margin-right:5px;" v-b-modal="name+'-leaderboard-history-modal-' + profile._id" @click="activeModalBtn(name+`-leaderboard-history-modal-'${profile._id}`)">
            <div class="d-flex gap-1">
                <div>
                    <img width="14" src="/gem-icon.png" alt="gem-icon">
                </div>
                <span>
                    {{ profile.gem }}
                </span>
            </div>
        </span>
        <b-modal :id="name+'-leaderboard-history-modal-' + profile._id" hide-footer size="lg" centered @hidden="resetModal">
            <template #modal-title>
                <div class="custom-header">
                <h5>
                    <img width="14" style="margin-right: 5px;" src="/gem-icon.png" alt="gem-icon">
                    Gems History |
                    {{ profile.name }}
                    ({{ profile.gem }} gems)
                </h5>
                </div>
            </template>
            <div class="modal_content">
                
                <b-overlay :show="show" rounded="sm">
                    <div v-if="myGems.total > 0" class="gems-list mt-0">
                        <div v-for="(itemData,indexData) in Helpers.gemsHistoryInGroup(myGems.data)" :key="indexData" class="mb-1">
                            <div class="ago">{{ itemData.ago }} - {{ itemData.date }}</div>
                            <div v-for="(item,index) in itemData.history" :key="index" class="gem-item">
                                <div class="d-flex align-items-center gap-2">
                                    <div class="icon">
                                        <template v-if="item.type">
                                            <img src="/lock-icon.png" alt="lock-icon">
                                        </template>
                                        <template v-else>
                                            <img src="/reward-icon.png" alt="gem-icon">
                                        </template>
                                    </div>
                                    <div>
                                        <template v-if="item.type">
                                            <template v-if="item.type == 'question-life-refill'">
                                                <strong class="heading">Refill lives</strong>
                                            </template>
                                            <template v-if="item.type == 'topic-purchased-with-gem'">
                                                <strong class="heading">Topic Unlocked</strong>
                                                <div class="sub-heading">
                                                    {{ item.topicName }} for {{ item.gradeName }}
                                                </div>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="item.gemId == 'new-profile-gem' || item.gemId == 'daily-claim-gem'">
                                                <strong class="heading">{{ item.message }}</strong>
                                            </template>
                                            <template v-else>
                                                <strong class="heading">Level Completed</strong>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <strong v-if="item.type" class="text-spend">
                                            -{{ item.spendGem }}
                                        </strong>
                                        <strong v-else class="text-reward">
                                            +{{ item.gem }}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-1 mb-1">
                            <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div class="dataTables_info mt-1">Showing {{myGems.from}} to {{myGems.to}} of {{myGems.total}} entries</div>
                                <pagination 
                                class="mt-1 mb-0"
                                :data="myGems"
                                :limit=5
                                @pagination-change-page="getProfileGemsHistory"
                                ></pagination>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        No Record Found!
                    </div>
                </b-overlay>
                
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BOverlay,
    BLink,
    BBadge,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTableSimple,
    BThead,
    BFormCheckbox,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCardHeader,
    BCardBody,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../Helpers';
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BOverlay,
        BLink,
        BBadge,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTableSimple,
        BThead,
        BFormCheckbox,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
        BCardHeader,
        BCardBody,
        BSpinner,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {},
            Helpers,
            activeModalName: null,
            myGems: {},
            show: false,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    methods: {
        activeModalBtn(modalName){
            this.activeModalName = modalName;
            this.getProfileGemsHistory();
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        getProfileGemsHistory: function (page = 1) {
            this.show = true;
            var url = process.env.VUE_APP_API_URL + `/profile-gems-history/${this.profile._id}?page=${page}`;
            var payload = {};
            axios
                .post(url, payload, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((res) => {
                    this.myGems = res.data.myGems;
                    this.show = false;
                })
                .catch(function (error) {
                    console.log("error", error);
                    this.show = false;
                });
        },
    },
    props: [
        "profile",
        "name"
    ],
};
</script>
